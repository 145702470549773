import { Warning } from '../components/Warning/Warning'
import { Content } from '../components/Content/Content'
import { useRecoilState } from 'recoil'
import { defaultActiveOrdersFilter } from 'utils/states/activeFilter.state'
import { MainContainer } from 'components/MainContainer/MainContainer'
import { MoneyStateOrders } from '../components/Content/components/MoneyStateOrders'
import { TimeArea } from '../../components/Timearea/TimeArea'
import { Filter } from '../../components/Filter/Filter'
import { ordersFilters } from 'utils/constants/filters'
import { RateOrders } from "../../components/Rate/RateOrders";
import styles from './styles.module.scss'
import {useState} from "react";

export const Orders = () => {
  const [{ activeOrdersFilter }, changeFilter] = useRecoilState(defaultActiveOrdersFilter)
  const [page, setPage] = useState<number>(1);
  const handleSetFilter = (filter : string) => {
    setPage(1);
    changeFilter({activeOrdersFilter: filter})
  }
  return (
    <MainContainer>
      <div className={styles.overflow}>
        <RateOrders />
        <Warning />
        <MoneyStateOrders />
        <h3 className={styles.title}>Заявки</h3>
        <TimeArea />
        <Filter activeFilter={activeOrdersFilter} changeFilter={handleSetFilter} filters={ordersFilters} />
        <Content page={page} setPage={setPage} />
      </div>
    </MainContainer>
  )
}
