import {useEffect, useState} from 'react'
import {operationThArray, overflowThArray} from 'utils/constants/th_arrays'
import {Table} from 'components/Table/Table'
import {useGetPaymentsQuery} from 'redux/services/PaymentsApi'
import {Loading} from 'components/Loading/Loading'
import {Pagination} from 'components/Pagination/Pagination'
import calculateElements from 'utils/functions/calcucateElements'
import mapPayments from 'utils/functions/map/mapPayments'

export const OperationsTable = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const {data, isLoading} = useGetPaymentsQuery({page, page_size: pageSize})
  const handleResize = () => {
    setPageSize(calculateElements([530, 580, 640, 690, 750, 810, 880], 10))
  }
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  if (isLoading) {
    return <Loading/>
  }
  const mappedPayments = mapPayments(data?.results) || []
  return (
    <>
      <Table
        thArray={operationThArray}
        tdArray={
          mappedPayments.length === 0
            ? []
            : mappedPayments.map((a) => ([
                {element: a.hash, style: {}},
                {element: a.status, style: {textAlign: 'center'}},
                {element: a.sum, style: {textAlign: 'center'}},
                {element: a.sum, style: {textAlign: 'center'}},
                {element: a.date, style: {textAlign: 'center'}},
              ])
            )
        }
      />
      <Pagination
        totalPages={Math.ceil((data?.count || 0) / pageSize)}
        setCurrentPage={setPage}
        currentPage={page}
        nextPage={data?.next || page}
        previousPage={data?.previous || page}
      />
    </>
  )
}
