export const AUTH = '/auth'
export const CARDS = '/cards'
export const APPLICATIONS = '/applications'
export const PAYMENTS = '/payments'
export const OPERATIONS = '/operations'
export const SETTINGS = '/settings'

export const WITHDRAWAL = '/withdrawal'
export const SERVER_ERROR = '/server-error'
export const FORBIDDEN_ERROR = '/forbidden-error'

export const DISPUTE = '/dispute_payment'