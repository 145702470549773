import {NavbarTrader} from "./NavbarTrader";
import {NavbarAgent} from "./NavbarAgent";
import {Link} from "react-router-dom";
import {AUTH} from "../../utils/constants/routes";
import {isAgent} from "../../utils/functions/getUserType";
import styles from "./Navbar.module.css";

export const Navbar = () => {
  return (
    <div className={styles.navBar}>
      <div className={styles.logo}>
        {' '}
        <img
          className={styles.logoImg}
          src={'/paygo.svg'}
          alt={'xproject_logo_navbar'}
        />
      </div>

      { isAgent ? <NavbarAgent/> : <NavbarTrader/>}
      <Link to={AUTH} className='link-style'>
        <div
          className={styles.button}
          style={{ color: '#FE5B37' }}
          onClick={() => {
            localStorage.removeItem('token')
          }}
        >
          <img src={'/logout_icon.png'} alt={'logout_icon'} /> Выход
        </div>
      </Link>
    </div>
  )
}
