import {type ReactNode} from 'react'
import {Header} from '../Header/Header'
import {useRecoilState} from 'recoil'
import {defaultIsMobile} from '../../utils/states/isMobile.state'
import {Navbar} from "../Navbar/Navbar";
import styles from './styles.module.scss'

interface TMainContainerProps {
  children: ReactNode
  needLogo?: boolean
  style?: React.CSSProperties
}

export const MainContainer = (props: TMainContainerProps) => {
  const {children, needLogo, style} = props
  const [version] = useRecoilState(defaultIsMobile)
  const isMobile = version.isMobile
  return (
    <div className={styles.container}>
      {isMobile ? <Header/> : <Navbar/>}
      <div className={styles.main}>
        {!isMobile && needLogo ? (
          <div className={styles.container_logo}>
            <img src={'/container-logo.png'} alt={'container logo'}/>
          </div>
        ) : null}
        <div className={styles.wrapper} style={style}>
          {children}
        </div>
      </div>
    </div>
  )
}
