import {Auth} from './pages/Auth/page/Auth'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {Payments} from './pages/Trader/Payments/page/Payments'
import {useEffect} from 'react'
import {ProtectedRouter} from './utils/services/ProtectedRouter'
import {ServerError} from './pages/Trader/Errors/ServerError'
import {ForbiddenError} from './pages/Trader/Errors/ForbiddenError'
import {NotFoundError} from './pages/Trader/Errors/NotFoundError'
import {
  APPLICATIONS,
  AUTH,
  CARDS,
  FORBIDDEN_ERROR, OPERATIONS,
  PAYMENTS,
  SERVER_ERROR,
  SETTINGS, WITHDRAWAL
} from './utils/constants/routes'
import {useRecoilState} from 'recoil'
import {defaultIsMobile} from './utils/states/isMobile.state'
import {AccountSettings} from './pages/Trader/AccountSettings/AccountSettings'
import {Cards} from './pages/Trader/Cards/page/Cards'
import {Orders} from './pages/Trader/OrdersAndWithdrawals/Orders/page/Orders'
import Withdrawal from './pages/Trader/OrdersAndWithdrawals/Withdrawals/page/Withdrawal'
import {useGetSelfQuery} from "./redux/services/TraderApi";
import {pushNotify} from "./components/Notifications";
import {Operations} from "./pages/Trader/Operations/page/Operations";

const INFINITY = 99999999999999;

export const App = () => {
  const [, setVersion] = useRecoilState(defaultIsMobile)
  const {data: trader} = useGetSelfQuery(undefined)
  useEffect(() => {
    if (trader?.block_reason) {
      trader?.block_reason.forEach(text => {
        pushNotify(text, '', INFINITY);
      })
    }
  }, [trader?.block_reason]);

  useEffect(() => {
    setVersion({isMobile: window.innerWidth <= 1024})

    const handleResize = () => {
      setVersion({isMobile: window.innerWidth <= 1024})
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <Router>
      <div className='App' style={{height: '100%'}}>
        <Routes>
          <Route path={'*'} element={<NotFoundError/>}/>
          <Route path={SERVER_ERROR} element={<ServerError/>}/>
          <Route path={FORBIDDEN_ERROR} element={<ForbiddenError/>}/>
          <Route path={''} element={<ProtectedRouter/>}>
            <Route path={CARDS} element={<Cards/>}/>
            <Route path={APPLICATIONS} element={<Orders/>}/>
            <Route path={WITHDRAWAL} element={<Withdrawal/>}/>
            <Route path={PAYMENTS} element={<Payments/>}/>
            <Route path={OPERATIONS} element={<Operations/>}/>
            <Route path={SETTINGS} element={<AccountSettings/>}/>
            <Route path={AUTH} element={<Auth/>}/>
            <Route path="/" element={<Auth/>}/>
          </Route>
        </Routes>
      </div>
    </Router>
  )
}
