import {type ReactNode} from 'react'
import {Header} from '../Header/Header'
import {useRecoilState} from 'recoil'
import {defaultIsMobile} from '../../utils/states/isMobile.state'
import {Navbar} from "../Navbar/Navbar";
import './RequisiteContainer.css'

interface TMainContainerProps {
  children: ReactNode
  style?: React.CSSProperties
}

const RequisiteContainer = (props: TMainContainerProps) => {
  const {children, style} = props
  const [version] = useRecoilState(defaultIsMobile)
  const isMobile = version.isMobile
  return (
    <div className={'cardsContainer'}>
      {isMobile ? <Header/> : <Navbar/>}
      <div className={'cardContent'}>
        <div className={'cardsWrapper'} style={style}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default RequisiteContainer
