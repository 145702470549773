import {Header} from '../components/Header/Header'
import {MainContainer} from 'components/MainContainer/MainContainer'
import {Content} from '../components/Content/Content'
import {useGetSelfQuery} from "redux/services/TraderApi";
import styles from './styles.module.scss';

export const Payments = () => {
  const {data: trader} = useGetSelfQuery(undefined);
  return (
    <MainContainer needLogo={false}>
      <div className={styles.overflow}>
        <Header balance={trader?.balance}/>
        <Content/>
      </div>
    </MainContainer>
  )
}
