import React, {FC} from 'react'
import { useRecoilState } from 'recoil'
import { defaultIsMobile } from '../../../../../../utils/states/isMobile.state'
import WithdrawalsBlocks from './components/Block/WithdrawalsBlocks'
import WithdrawalsTable from './components/Table/WithdrawalsTable'

export const Content: FC<any> = ({ page, setPage }) => {
  const [version] = useRecoilState(defaultIsMobile)
  const isMobile = version.isMobile
  return (
    <div>
      {(isMobile) ? <WithdrawalsBlocks page={page} setPage={setPage} /> : <WithdrawalsTable page={page} setPage={setPage} />}
    </div>
  )
}

