import React, {useCallback, useRef, useState} from 'react'
import styles from './Selector.module.css'
import { useOnCloseOutside } from './hooks/useOnCloseOutside';
import { IOrdersSelectorProps } from './types';

export const Selector = (props: IOrdersSelectorProps) => {
  const {
    id,
    status,
    statusId,
    handleEdit,
    needRecalculation = false,
    setOpen,
    open,
    pageName,
  } = props;
  const [disabled, setDisabled] = useState<boolean>(false);
  const selectorRef = useRef<HTMLDivElement>(null);

  const handleSwitch = useCallback((event: React.MouseEvent) => {
    if (disabled) return;
    event.stopPropagation();
    setOpen(prevId => prevId === id ? null : id);
  }, [id, setOpen]);

  const handleClose = useCallback(() => {
    setOpen(null);
  }, [setOpen]);

  const isOpen = open === id;

  useOnCloseOutside(selectorRef, handleClose, 'mousedown', isOpen);

  const changeStatus = async (event: any, newStatus: string) => {
    if (disabled) return;
    event.stopPropagation();
    handleClose();
    setDisabled(true);
    await handleEdit(newStatus, id)
    setDisabled(false);
  }

  if (disabled) {
    return (
      <div className={styles.wrapper}>
        <div className={`${styles.head} ${styles.none}`}>
          <span>Выполняется запрос</span>
        </div>
      </div>
    )
  }

  if (statusId !== 'BN' && statusId !== 'ID') {
    return (
      <div className={styles.wrapper}>
        <div className={`${styles.head} ${styles.none}`}>
          <span>{status}</span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper} ref={selectorRef}>
      <div className={`${styles.head} ${styles.select_active}`} onClick={handleSwitch}>
        {status}
        <img
          src={isOpen ? '/arrow_up_icon.png' : '/arrow_down_icon.png'}
          alt={'arrow icon'}
        />
      </div>
      {isOpen && (
        <ul id={id} onClick={(e) => e.stopPropagation()}>
          <li
            onClick={(event) =>
              changeStatus(event, statusId === 'ID' && pageName !== 'withdraw' ? 'FR' : 'SF')}
          >
            Успешно завершенные
          </li>
          <li
            onClick={(event) =>
              changeStatus(event, pageName === 'withdraw' ? 'CANCELED' : 'CR')}
          >
            Отказ
          </li>
          {needRecalculation && statusId !== 'ID' && (
            <li
              onClick={(event) =>
                changeStatus(event, 'IP')}
            >
              Заявка с перерасчетом
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
