import React from 'react'
import styles from './Navbar.module.css'
import {Link, useLocation} from 'react-router-dom'
import {APPLICATIONS, AUTH, CARDS, PAYMENTS, SETTINGS, WITHDRAWAL} from '../../utils/constants/routes'
import {useVisible} from '../../utils/hooks/useVisible'

export const NavbarTrader = () => {
  const location = useLocation()
  const {notVisible} = useVisible()
  if (notVisible) return null
  return (
    <>
      <Link to={CARDS} className='link-style'>
        <div className={location.pathname === CARDS ? styles.activeButton : styles.button}>
          <img
            src={location.pathname === CARDS ? '/cards_icon_active.png' : '/cards_icon.png'}
            alt={'cards_icon'}
          />{' '}
          Реквизиты
        </div>
      </Link>
      <Link to={PAYMENTS} className='link-style'>
        <div className={location.pathname === PAYMENTS ? styles.activeButton : styles.button}>
          <img
            src={
              location.pathname === PAYMENTS
                ? '/replenishment_icon_active.png'
                : '/replenishment_icon.png'
            }
            alt={'replenishment_icon'}
          />{' '}
          Баланс
        </div>
      </Link>
      <Link to={APPLICATIONS} className='link-style'>
        <div className={location.pathname === APPLICATIONS ? styles.activeButton : styles.button}>
          <img
            src={
              location.pathname === APPLICATIONS
                ? '/application_icon_active.svg'
                : '/application_icon.svg'
            }
            alt={'application'}
          />{' '}
          Пополнение
        </div>
      </Link>
      <Link to={WITHDRAWAL} className='link-style'>
        <div className={location.pathname === WITHDRAWAL ? styles.activeButton : styles.button}>
          <img
            src={
              location.pathname === WITHDRAWAL
                ? '/withdraw_icon_active.svg'
                : '/withdraw_icon.svg'
            }
            alt={'settings_icon'}
          />{' '}
          Вывод
        </div>
      </Link>
      <Link to={SETTINGS} className='link-style'>
        <div className={location.pathname === SETTINGS ? styles.activeButton : styles.button}>
          <img
            src={
              location.pathname === SETTINGS ? '/phone_mobile_icon.svg' : '/phone_mobile_icon.svg'
            }
            alt={'settings_icon'}
          />{' '}
          Устройства
        </div>
      </Link>
    </>
  )
}
