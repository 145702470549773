import {MainContainer} from 'components/MainContainer/MainContainer'
import {Content} from "../Content/Content";
import {Header} from "../Header/Header";

export const Operations = () => {
  return (
    <MainContainer needLogo={false}>
      <div className={'overflow'} style={{height: '100%', position: 'relative'}}>
        <Header/>
        <Content/>
      </div>
    </MainContainer>
  )
}
