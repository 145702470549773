import { TWithdrawalsResponse } from '../../../redux/types/WithdrawalsTypes'
import { getStatusWithdrawalsById } from '../getStatusById'

export type WithdrawalsModel = {
  id: string
  status: string
  statusId: string
  expected_income: string
  date: string
  bank_name: string
  first_name: string
  last_name: string
  card_number: string
  account_extra_info: string
  bank_full_name: string
  time_left: number
  trader_reward_amount: number
  files: any[]
}

export const mapWithdrawals = (withdrawals : TWithdrawalsResponse[] | undefined) : WithdrawalsModel[] => {
  return (
    withdrawals?.map((item: TWithdrawalsResponse) => ({
      id: item.id,
      status: getStatusWithdrawalsById(item.status),
      statusId: item.status,
      expected_income: (item.amount + '').replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
      date: item.create_dt,
      first_name: item.first_name,
      last_name: item.last_name,
      bank_name : item.bank_name,
      card_number : item.account_number,
      account_extra_info: item.account_extra_info,
      bank_full_name: item.bank_full_name,
      time_left: item.time_left,
      trader_reward_amount: item.trader_reward_amount,
      files: item.files,
    })) || []
  )
}