import {FC, useMemo, useRef, useState} from 'react';
import {getShortString} from "utils/functions/getShortString";
import styles from './style.module.scss';
import classNames from "classnames";

interface IUploadImageProps {
  updateImage: (file: any) => void;
}

const IS_ALLOWED_FORMATS = ['bmp', 'jpg', 'png', 'gif', 'webp', 'jpeg', 'pdf'];
export const ERROR = 'ERROR';

export const UploadImage: FC<IUploadImageProps> = ({updateImage}) => {
  const ref = useRef<any>();
  const openUploader = () => ref?.current?.click?.();
  const [errors, setErrors] = useState<string[]>([])
  const [value, setValue] = useState<any | null>(null)

  const upload = async (event: any) => {
    const Mb = 1024 * 1024;
    const file = event.target.files?.[0];

    setValue(file);
    let errorList = [];
    if (file.size > 5 * Mb) {
      const errorText = `Слишком большой размер файла. Пожалуйста, используйте файлы с размером не превышающим 5 Mb`
      errorList.push(errorText);
    }
    const format = file.name.split('.')?.slice(-1)?.[0];
    if (!IS_ALLOWED_FORMATS.includes(format)) {
      const errorText = `Расширение файлов “${format}” не поддерживается. Разрешенные расширения: ${IS_ALLOWED_FORMATS.join(', ')}`
      errorList.push(errorText);
    }
    setErrors(errorList);
    updateImage(errorList.length ? ERROR : file);
  }

  return (
    <div className={styles.downloader}>
      <div onClick={openUploader} className={styles.files}>
        {value
          ? <p key={value.name}>
              {getShortString(value.name)}
            </p>
          : 'Загрузить чек'
        }
      </div>
			{errors && errors.map(error =>
        <div className={styles.error}>
          {error}
        </div>
      )}
      <input
        ref={ref}
        type="file"
        id="input"
        accept="image/bmp, image/jpg, image/png, image/gif, image/webp, image.jpeg, application/pdf"
        onChange={upload}
        className={styles.hidden}
      />
    </div>
  );
};
