import React, { useState } from 'react'
import { Blocks } from '../../../../../../../components/Blocks/Blocks'
import { useGetPaymentsQuery } from '../../../../../../../redux/services/PaymentsApi'
import { Loading } from '../../../../../../../components/Loading/Loading'
import mapPayments from '../../../../../../../utils/functions/map/mapPayments'
import { Pagination } from '../../../../../../../components/Pagination/Pagination'

export const PaymentsBlocks = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, ] = useState<number>(10)
  const { data, isLoading } = useGetPaymentsQuery({ page, page_size: pageSize })
  const payments = data?.results
  const mappedPayments = mapPayments(payments) || []
  if (isLoading) {
    return <Loading />
  }
  return (
    <div>
      <Blocks
        blocks={mappedPayments.map((a) => {
          return (
            <div className={'block'} key={a.id}>
              <div className={'component'}>
                <p className={'block-component'}>Хэш транзакции:</p>
                <p className={'block-value'}>{
                  a.status === 'success'
                    ? <a href={`https://tronscan.org/#/transaction/${a.hash}`}>
                      {a.hash}
                    </a>
                    : ''
                }</p>
              </div>
              <div className={'component'}>
                <p className={'block-component'}>Адрес кошелька:</p>
                <p className={'block-value'}>{a.wallet_to}</p>
              </div>
              <div className={'component'}>
                <p className={'block-component'}>Статус:</p>
                <p className={'block-value'}>{a.status}</p>
              </div>
              <div className={'component'}>
                <p className={'block-component'}>Тип платежа:</p>
                <p className={'block-value'}>{a.type}</p>
              </div>
              <div className={'component'}>
                <p className={'block-component'}>Сумма (USDT):</p>
                <p className={'block-value'}>{a.sum}</p>
              </div>
              <div className={'component'}>
                <p className={'block-component'}>Дата создания:</p>
                <p className={'block-value'}>{a.date}</p>
              </div>
            </div>
          )
        })}
      />
      <Pagination
        totalPages={Math.ceil((data?.count || 0) / pageSize)}
        setCurrentPage={setPage}
        currentPage={page}
        nextPage={data?.next || page}
        previousPage={data?.previous || page}
      />
    </div>
  )
}
