import { settingsThArray } from 'utils/constants/th_arrays'
import { Table } from 'components/Table/Table'
import { useState } from 'react'
import { useGetDevicesQuery } from 'redux/services/DevicesApi'
import { Loading } from 'components/Loading/Loading'
import Status from '../components/Status/Status'
import DeviceName from '../components/DeviceName/DeviceName'
import Actions from '../components/Actions/Actions'
import { Pagination } from 'components/Pagination/Pagination'
import styles from './styles.module.scss';

const SettingsTable = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, ] = useState<number>(7)
  const { data: devices, isLoading: isDevicesLoading } = useGetDevicesQuery(
    { page, page_size: pageSize },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  if (isDevicesLoading) {
    return <Loading />
  }
  return (
    <div className={styles.overflow}>
      <Table
        thArray={settingsThArray}
        tdArray={
          devices?.results?.map((device) => {
            return [
              {
                element: (
                  <DeviceName name={device.name} date={device.create_dt} model={device.model} />
                ),
                style: {},
              },
              { element: device.id, style: {} },
              { element: device.app_version, style: {} },
              { element: <Status status={device.is_online} />, style: {} },
              { element: <Actions deviceId={device.id} />, style: {} },
            ]
          }) || []
        }
      > </Table>
      <Pagination
        totalPages={Math.ceil((devices?.count || 0) / pageSize)}
        setCurrentPage={setPage}
        currentPage={page}
        nextPage={devices?.next || page}
        previousPage={devices?.previous || page}
      />
    </div>
  )
}

export default SettingsTable
