import { useGetSelfQuery } from 'redux/services/TraderApi'
import { Loading } from 'components/Loading/Loading'
import formatNumber from "utils/functions/formatNumber";
import styles from './styles.module.css'

export const Header = () => {
  const { data: trader, isLoading } = useGetSelfQuery(undefined, { pollingInterval: 5000 })
  if (isLoading) {
    return <Loading />
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <h3 className={styles.title}>Принято средств трейдерами</h3>
        <h4 className={styles.item}>
          USDT<span className={styles.value}>{formatNumber(Number(trader?.balance))}</span>
        </h4>
        <h4 className={styles.item}>
          RUB<span className={styles.value}>{formatNumber(Number(trader?.balance))}</span>
        </h4>
      </div>
    </div>
  )
}
